.info-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    color: #333;
}

.info-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    text-transform: uppercase;
}

.info-subtitle {
    font-size: 2rem;
    font-weight: bold;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
}

.info-text {
    font-size: 1.125rem;
    margin-bottom: 1rem;
}

.info-list {
    font-size: 1.125rem;
    list-style-type: disc;
    padding-left: 2rem;
    margin-bottom: 1rem;
}

.info-list li {
    margin-bottom: 0.5rem;
}

.info-link {
    color: #0066cc;
    text-decoration: none;
}

.info-link:hover {
    text-decoration: underline;
}

/* Light theme styles */
.light .info-container .info-text {
    color: #2c3e50; /* Updated font color for light theme */
  }
  
  .light .info-link {
    color: #1a73e8;
  }
  
  .light .info-link:hover {
    text-decoration: underline;
  }
  
  /* Dark theme styles */
  .dark .info-container {
    color: #bdc3c7; /* Updated font color for dark theme */
  }
  
  .dark .info-text {
    color: #bdc3c7; /* Updated font color for dark theme */
  }
  .dark .info-list {
    color: #bdc3c7; /* Updated font color for dark theme */
  }
  
  .dark .info-link {
    color: #6caeff;
  }
  
  .dark .info-link:hover {
    text-decoration: underline;
  }