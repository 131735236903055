.App {
  text-align: center;
  padding-left: calc(100vw - 100%);
}

body::before {
  content: "";
  background-image: url('./assets/native-space.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  opacity: 0.35; /* Adjust the transparency between 0 (completely transparent) and 1 (completely opaque) */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.site-layout-content {
  /* background-color: white; */
  padding: 24px;
  min-height: 380px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

h1 {

  font-weight: 700;
  margin-bottom: 16px;
}

h2 {

  font-weight: 500;
  margin-bottom: 8px;
}

p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 24px;
}

.ant-btn-primary {
  background-color: #3e577c;
  border-color: #3e577c;
  font-weight: 500;
  border-radius: 4px;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: #2d4464;
  border-color: #2d4464;
}

/* Table styles */
.ant-table {
  margin-top: 24px;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: #f0f2f5;
}

.ant-table-thead > tr > th {
  background-color: #3e577c;
  color: white;
  font-weight: bold;
  border-radius: 4px;
}

/* Header styles */
.navigation-menu {
  background-color: #3e577c;
  font-size: 16px;
  font-weight: 500;
  border-radius: 0;
}

.navigation-menu-item {
  color: white;
}

.navigation-menu-item:hover {
  background-color: #2d4464;
  color: white;
}

.bonds-intro {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

/* Bonds page */
.bonds-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 24px;
}

.bonds-card {
  margin-bottom: 24px;
  height: 100%;
}

@media (max-width: 768px) {
  .bonds-card {
    margin-bottom: 16px;
  }
}

.bonds-intro > p {
  flex: 0 0 calc(50% - 16px);
  margin-bottom: 32px;
}

@media (max-width: 768px) {
  .bonds-intro > p {
    flex: 100%;
  }
}

.bonds-actions {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}

.bonds-button {
  margin-bottom: 128px;
}

.bonds-actions > * {
  flex: 1;
}

.water-ripple-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.bond-page-container {
  margin-left: 4%;
  margin-right: 4%;
  margin-top: 2%;
}